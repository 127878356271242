import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PriceTable from '../containers/price-table';

const Prices = () => {
  const data = useStaticQuery(graphql`
    query PricesImages {
      piano: file(relativePath: { eq: "pie-piano.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Precios" />
      <section className="prices lg:pt-12">
        <div className="container mx-auto px-3">
          <PriceTable />
        </div>
        <div className="container mx-auto">
          <div className=" max-w-3xl mr-0 ml-auto">
            <GatsbyImage
              image={data.piano.childImageSharp.gatsbyImageData}
              alt="Piano"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Prices;
