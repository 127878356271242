import React from 'react';
import CheckYes from '../assets/check-si.svg';
import CheckNo from '../assets/check-no.svg';

const PriceTable = () => {
  return (
    <table className="bg-white border-white border text-xs table-fixed lg:text-base">
      <thead className="border-purple-500 border-b">
        <tr>
          <th
            rowSpan="4"
            className="text-left uppercase w-1/3 lg:w-1/4"
            valign="bottom"
          >
            <div className="text-gray-500">Nuestros</div>
            <h2 className="font-header leading-none text-purple-500 text-3xl lg:text-5xl mb-6">
              Precios
            </h2>
          </th>
          <th className="w-1/3 lg:w-1/4" />
          <th className="w-1/4 hidden lg:table-cell">
            <div className="font-header leading-none bg-orange-500 mx-auto rounded-full text-white flex items-center justify-center font-light text-lg w-10 h-10 lg:text-2xl lg:w-20 lg:h-20">
              12%
            </div>
          </th>
          <th className="w-1/4 hidden lg:table-cell">
            <div className="font-header leading-none bg-purple-500 mx-auto rounded-full text-white flex items-center justify-center font-light text-lg w-10 h-10 lg:text-2xl lg:w-20 lg:h-20">
              15%
            </div>
          </th>
        </tr>
        <tr>
          <th className="border-purple-500 border border-t-0">
            <div className="font-header text-orange-500 uppercase text-center text-lg font-light lg:font-bold lg:text-4xl">
              Paquete 1
            </div>
            <div className="text-gray-500 text-center text-xs lg:text-sm">
              Pago mensual
            </div>
          </th>
          <th className="hidden border-purple-500 border border-t-0 lg:table-cell">
            <div className="font-header text-orange-500 uppercase text-center text-lg font-light lg:font-bold lg:text-4xl">
              Paquete 2
            </div>
            <div className="text-gray-500 text-center text-xs lg:text-sm">
              Pago anual
            </div>
          </th>
          <th className="hidden border-purple-500 border border-t-0 lg:table-cell">
            <div className="font-header text-orange-500 uppercase text-center text-lg font-light lg:font-bold lg:text-4xl">
              Paquete 3
            </div>
            <div className="text-gray-500 text-center text-xs lg:text-sm">
              Pago anual hermanitos
            </div>
          </th>
        </tr>
        <tr className="bg-purple-gradient">
          <th className="border-purple-500 border">
            <div className="flex items-center justify-center text-white flex-col lg:flex-row">
              <div className="text-white font-bold text-lg lg:text-4xl">
                $157
              </div>
              <span className="font-light ml-1 text-xs lg:text-base">
                x mes
              </span>
            </div>
          </th>
          <th className="hidden border-purple-500 border lg:table-cell">
            <div className="flex items-center justify-center text-white flex-col lg:flex-row">
              <div className="text-white font-bold text-lg lg:text-4xl">
                $138
              </div>
              <span className="font-light ml-1 text-xs lg:text-base">
                x mes
              </span>
            </div>
          </th>
          <th className="hidden border-purple-500 border lg:table-cell">
            <div className="flex items-center justify-center text-white flex-col lg:flex-row">
              <div className="text-white font-bold text-lg lg:text-4xl">
                $133
              </div>
              <span className="font-light ml-1 text-xs lg:text-base">
                x mes
              </span>
            </div>
          </th>
        </tr>
        <tr>
          <th className="py-2 border-purple-500 border bg-orange-100 text-gray-500 lg:px-4">
            Cancela tus clases mes a mes, mediante un cobro automático a tu
            tarjeta de crédito.
          </th>
          <th className="py-2 border-purple-500 border bg-teal-100 text-gray-500 hidden lg:table-cell lg:px-4">
            Cancela 12 meses de clases y difiérelo sin intereses con el 12% de
            descuento.
          </th>
          <th className="py-2 border-purple-500 border bg-purple-100 text-gray-500 hidden lg:table-cell lg:px-4">
            Cancela 12 meses de clases y difiérelo sin intereses con el 15% de
            decuento, si inscribes a tus dos hijos.
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            2 clases semanales
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            Instrumento para práctica{' '}
            <span className="text-orange-500">en clase</span>
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            1 recital al año
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            1 sesión de fotos <span className="text-orange-500">(gratis)</span>
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            Talleres para padres{' '}
            <span className="text-orange-500">(gratis)</span>
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="border-purple-500 border border-l-0">
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            Evaluaciones personalizadas para medir el avance
          </td>
          <td className="py-2 border-purple-500 border bg-orange-100">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-teal-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 bg-purple-100 border-purple-500 border hidden lg:table-cell">
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr>
          <td className="py-2 text-gray-500 font-bold uppercase lg:pr-2">
            Ahorro anual
          </td>
          <td className="py-2 border-purple-500 border border-b-0 bg-orange-100">
            <CheckNo className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
          <td className="py-2 border-purple-500 border border-b-0 bg-teal-100 text-center hidden lg:table-cell">
            Ahorro de
            <div className="font-header leading-none text-orange-500 uppercase text-lg lg:text-xl">
              $226 dólares
            </div>
          </td>
          <td className="py-2 border-purple-500 border border-b-0 bg-purple-100 text-center hidden lg:table-cell">
            Ahorro de
            <div className="font-header leading-none text-orange-500 uppercase text-lg lg:text-xl">
              $565 dólares
            </div>
          </td>
        </tr>

        <tr className="lg:hidden">
          <th
            rowSpan="4"
            className="border-purple-500 border-b text-left uppercase"
            valign="bottom"
          >
            <div className="text-gray-500">Nuestros</div>
            <h2 className="font-header leading-none text-purple-500 text-3xl lg:text-5xl mb-6">
              Precios
            </h2>
          </th>
          <th colSpan="2">
            <div className="mt-6 font-header leading-none bg-orange-500 mx-auto rounded-full text-white flex items-center justify-center font-light text-lg w-10 h-10 lg:text-2xl lg:w-20 lg:h-20">
              12%
            </div>
          </th>
        </tr>
        <tr className="border-purple-500 border-b lg:hidden">
          <th colSpan="2" className="border-purple-500 border border-t-0">
            <div className="font-header text-orange-500 uppercase text-center text-lg font-light lg:font-bold lg:text-4xl">
              Paquete 2
            </div>
            <div className="text-gray-500 text-center text-xs lg:text-sm">
              Pago anual
            </div>
          </th>
        </tr>
        <tr className="bg-purple-gradient lg:hidden">
          <th colSpan="2" className="border-purple-500 border">
            <div className="flex items-center justify-center text-white flex-col lg:flex-row">
              <div className="text-white font-bold text-lg lg:text-4xl">
                $138
              </div>
              <span className="font-light ml-1 text-xs lg:text-base">
                x mes
              </span>
            </div>
          </th>
        </tr>
        <tr className="lg:hidden">
          <th
            className="py-2 px-4 border-purple-500 border bg-teal-100 text-gray-500"
            colSpan="2"
          >
            Cancela 12 meses de clases y difiérelo sin intereses con el 12% de
            descuento.
          </th>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            2 clases semanales
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Instrumento para práctica{' '}
            <span className="text-orange-500">en clase</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            1 recital al año
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            1 sesión de fotos <span className="text-orange-500">(gratis)</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Talleres para padres{' '}
            <span className="text-orange-500">(gratis)</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Evaluaciones personalizadas para medir el avance
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-teal-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-b-0 border-l-0 text-gray-500 font-bold uppercase">
            Ahorro anual
          </td>
          <td
            className="px-4 py-2 border-purple-500 border border-b-0 bg-teal-100 text-center"
            colSpan="2"
          >
            Ahorro de
            <div className="font-header leading-none text-orange-500 uppercase text-lg lg:text-xl">
              $226 dólares
            </div>
          </td>
        </tr>

        <tr className="lg:hidden">
          <th
            rowSpan="4"
            className="border-purple-500 border-b text-left uppercase"
            valign="bottom"
          >
            <div className="text-gray-500">Nuestros</div>
            <h2 className="font-header leading-none text-purple-500 text-3xl lg:text-5xl mb-6">
              Precios
            </h2>
          </th>
          <th colSpan="2">
            <div className="mt-6 font-header leading-none bg-purple-500 mx-auto rounded-full text-white flex items-center justify-center font-light text-lg w-10 h-10 lg:text-2xl lg:w-20 lg:h-20">
              15%
            </div>
          </th>
        </tr>
        <tr className="border-purple-500 border-b lg:hidden">
          <th colSpan="2" className="border-purple-500 border border-t-0">
            <div className="font-header text-orange-500 uppercase text-center text-lg font-light lg:font-bold lg:text-4xl">
              Paquete 3
            </div>
            <div className="text-gray-500 text-center text-xs lg:text-sm">
              Pago anual hermanitos
            </div>
          </th>
        </tr>
        <tr className="bg-purple-gradient lg:hidden">
          <th colSpan="2" className="border-purple-500 border">
            <div className="flex items-center justify-center text-white flex-col lg:flex-row">
              <div className="text-white font-bold text-lg lg:text-4xl">
                $133
              </div>
              <span className="font-light ml-1 text-xs lg:text-base">
                x mes
              </span>
            </div>
          </th>
        </tr>
        <tr className="lg:hidden">
          <th
            className="py-2 px-4 border-purple-500 border bg-purple-100 text-gray-500"
            colSpan="2"
          >
            Cancela 12 meses de clases y difiérelo sin intereses con el 15% de
            decuento, si inscribes a tus dos hijos.
          </th>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            2 clases semanales
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Instrumento para práctica{' '}
            <span className="text-orange-500">en clase</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            1 recital al año
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            1 sesión de fotos <span className="text-orange-500">(gratis)</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Talleres para padres{' '}
            <span className="text-orange-500">(gratis)</span>
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-l-0 text-gray-500 font-bold uppercase">
            Evaluaciones personalizadas para medir el avance
          </td>
          <td
            className="px-4 py-2 border-purple-500 border bg-purple-100"
            colSpan="2"
          >
            <CheckYes className="w-6 h-6 mx-auto lg:w-12 lg:h-12" />
          </td>
        </tr>
        <tr className="lg:hidden">
          <td className="py-2 border-purple-500 border border-b-0 border-l-0 text-gray-500 font-bold uppercase">
            Ahorro anual
          </td>
          <td
            className="px-4 py-2 border-purple-500 border border-b-0 bg-purple-100 text-center"
            colSpan="2"
          >
            Ahorro de
            <div className="font-header leading-none text-orange-500 uppercase text-lg lg:text-xl">
              $565 dólares
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PriceTable;
